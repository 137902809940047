//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
    background-color: #f2f5f7 !important;

    .hover {
        @media (hover) {
            cursor: pointer;
        }
    }
}

.clickable{
    cursor: pointer;
}
