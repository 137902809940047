// Styles
#root {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #6c757d;
}

// Main styles

@import './config/purple/bootstrap.scss';
@import './config/purple/app.scss';

// Hyper Icons
@import './icons.scss';