.ant-transfer {
  width: 95%;
  height: 300px;
  margin-bottom: 10px;

  .ant-transfer-list {
    width: 48%;
    height: 100%;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 1px 4px 0px #d0d0d0;
  }

  .ant-transfer-operation {
    width: 4%;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    .ant-transfer-list {
      width: 100%;
      min-height: 200px;
    }
    .ant-transfer-operation {
      width: 100%;
      height: 40px;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        margin-left: 15px;
      }

      button:first-child {
        transform: rotate(-90deg);
        margin-bottom: 0;
      }
      button:last-child {
        transform: rotate(-90deg);
      }
    }
  }
}

.empty-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e2e2e2;

  i {
    font-size: 4rem;
  }

  h4 {
    color: #e2e2e2;
  }
}

.pic-button {
  @media screen and (min-width: 992px) and (max-width: 1600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.5rem !important;
  }
  @media screen and (max-width: 991px) {
    margin-top: 0px !important;
    margin-bottom: 1.5rem;
  }
}

.auth-page {
  overflow: auto;
  max-height: 750px;

  @media screen and (min-height: 700px) and (max-height: 860px) {
    max-height: 580px;
  }

  @media screen and (max-height: 699px) {
    max-height: 480px;
  }
}

.paciente-card {
  :hover {
    cursor: pointer;
  }
}

.quadroRow {
  flex-wrap: nowrap;
  overflow: auto;
}

#click-dropdown {
  background-color: #fff;
  padding: 0px 5px 0px 5px;
  margin: 5px 3px 0px 3px;
}

#click-dropdown:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}

#dropdown-fileuploader {
  width: 250px;
}

.fc-view-harness {
  overflow: auto !important;
  min-height: 500px !important;
}

.fc-view {
  min-width: 600px !important;
}

.fc-scrollgrid {
  width: 100% !important;
}

.nav-item {
  margin-bottom: 10px;
}
