//
// react-table.scss
//

.react-table {
    th,
    td {
        white-space: nowrap;
    }
    th {
        padding-left: $table-cell-padding-y;
        padding-right: 30px;
    }

    .sortable {
        cursor: pointer;
        div:last-child {
            display: inline-block;
            &:before {
                margin-left: 10px;
                content: "\2191";
                font-family: "Material Design Icons";
                font-size: 1rem;
                opacity: 0.7;
            }
            &:after {
                content: "\2193";
                font-family: "Material Design Icons";
                opacity: 0.7;
                font-size: 1rem;
            }
        }
        div:first-child {
            display: inline-block;
        }
        &.sorting_asc {
            div {
                &:after {
                    opacity: 1;
                }
            }
        }
        &.sorting_desc {
            &:after {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        li.paginate_button.previous,
        li.paginate_button.next {
            display: inline-block;
            font-size: 1.5rem;
        }
    }
}
